// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

require("stylesheets/application.scss");
require("stylesheets/app/modal.scss");
require("stylesheets/app/proprietaire/candidatures.scss");
require("stylesheets/app/proprietaire/properties.scss");
require("stylesheets/app/partenaire/landlords.scss");
require("stylesheets/app/partenaire/properties.scss");
require("stylesheets/app/partenaire/properties.scss");
require("stylesheets/zenbar.scss");

import "controllers";

import * as Sentry from "@sentry/browser";

// Ahoy Tracking GEM
import ahoy from "ahoy.js";
ahoy.configure({
  cookieDomain: "oqoro.com",
});
window.ahoy = ahoy;

// View Components
import "./components";

import "libs/form";

import "@lottiefiles/lottie-player";

import "chartkick/chart.js"


// To move to an appropriate file
// Used to redirect when turbo frame is missing from response (ex after form succeed redirect)
document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response);
});

// Fixes overriding turbo-frame header when provided https://github.com/hotwired/turbo/pull/579
document.addEventListener("turbo:before-fetch-request", (event) => {
  const targetTurboFrame = event.target.getAttribute("data-turbo-frame");
  const fetchTurboFrame = event.detail.fetchOptions.headers["Turbo-Frame"];
  if (
    targetTurboFrame &&
    targetTurboFrame != fetchTurboFrame &&
    document.querySelector(`turbo-frame#${targetTurboFrame}`)
  ) {
    event.detail.fetchOptions.headers["Turbo-Frame"] = targetTurboFrame;
  }
});

// Implement custom stream action to force redirect when format is turbo_stream
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};
