import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "loyerHcInput",
    "moisDgInput",
    "montantDgInput",
    "autoComputeHonorairesInput",
    "honorairesTotalLoiAlurInput",
    "honorairesFraisMelInput",
    "honorairesFraisMelProprioInput",
    "montantProvisionsChargesInput",
    "isFurnishedInput",
    "honosMelBpProprioPercentInput",
    "honosMelBpProprioEurosInput",
    "honosMelBpProprioEurosPerMeterInput",
    "honosMelBpLocatairePercentInput",
    "honosMelBpLocataireEurosInput",
    "honosMelBpLocataireEurosPerMeterInput",
    "propertyAreaInput",
  ];

  #moisDgInputController;

  connect() {
    this.actualiseSelectNbMoisDg();
  }

  actualiseMontantDepotGarantie() {
    const moisDg = this.moisDgInputTarget?.value && this.moisDgInputTarget.value[0] && parseFloat(this.moisDgInputTarget.value[0].value);
    const loyer = parseFloat(this.loyerHcInputTarget.value);

    if (!isNaN(moisDg)) {
      this.montantDgInputTarget.value = loyer * moisDg;
      alert(`Le montant du dépôt de garantie a été mis à jour : ${this.montantDgInputTarget.value} €`);
    } else {
      alert("Le montant du dépôt de garantie est fixé librement");
    }
  }

  computeHonoraires() {
    const type = this.element.querySelector("[name='form_object[type]']").value;
    if (type !== "Lot::Parking") {
      const checkedBoolAutoComputeHonos = this.autoComputeHonorairesInputTargets.filter((target) => target.checked)[0];
      const boolAutoComputeHonosIsTrue = checkedBoolAutoComputeHonos.value === "true";
      const honorairesTotalLoiAlur = parseFloat(this.honorairesTotalLoiAlurInputTarget.value);

      if (boolAutoComputeHonosIsTrue) {
        const honoraireLocataire = this.honorairesFraisMelInputTarget;
        const honoraireProprio = this.honorairesFraisMelProprioInputTarget;

        // Assignation des honoraires + gestion ALUR
        honoraireLocataire.value = Math.min(this.melLocataireTheorique(), honorairesTotalLoiAlur);
        honoraireProprio.value = this.melProprioTheorique();
        alert("Le montant des honoraires à été mis à jour : " + honoraireLocataire.value + "€ (locataire) et " + honoraireProprio.value + "€ (propriétaire)");
      }
    }
  }
  
  honorairesEdites() {
    const type = this.element.querySelector("[name='form_object[type]']").value;
    if (type !== "Lot::Parking") {
      const checkedBoolAutoComputeHonosInput = this.autoComputeHonorairesInputTargets.filter((target) => target.checked)[0];
      const boolAutoComputeHonosIsTrue = checkedBoolAutoComputeHonosInput.value === "true";

      if (
        (this.melProprioTheorique() !== this.honorairesFraisMelProprioInputTarget.value ||
          this.melLocataireTheorique() !== this.honorairesFraisMelInputTarget.value) &&
        boolAutoComputeHonosIsTrue
      ) {
        const uncheckedAutoComputeHonosInput = this.autoComputeHonorairesInputTargets.filter((target) => !target.checked)[0];
        checkedBoolAutoComputeHonosInput.checked = false;
        uncheckedAutoComputeHonosInput.checked = true;
        alert("Les honoraires sont fixés librement");
      }
    }
  }

  melProprioTheorique() {
    const loyerHc = this.loyerHcInputTarget.value;
    const charges = this.montantProvisionsChargesInputTarget.value;
    const loyerCc = parseFloat(loyerHc) + parseFloat(charges);
    const melProprioPercent = this.honosMelBpProprioPercentInputTarget.value / 100;
    const melProprioEuros = parseFloat(this.honosMelBpProprioEurosInputTarget.value);
    const melProprioEurosPerMeter = parseFloat(this.honosMelBpProprioEurosPerMeterInputTarget.value);
    let honorairesProprio = (loyerCc / 4) * 3;

    if (melProprioEuros) {
      honorairesProprio = parseFloat(melProprioEuros);
    } else if (melProprioPercent) {
      honorairesProprio = loyerCc * melProprioPercent;
    } else if (melProprioEurosPerMeter) {
      honorairesProprio = parseFloat(melProprioEurosPerMeter) * parseFloat(this.propertyAreaInputTarget.value);
    }
    return honorairesProprio;
  }

  melLocataireTheorique() {
    const loyerHc = this.loyerHcInputTarget.value;
    const charges = this.montantProvisionsChargesInputTarget.value;
    const loyerCc = parseFloat(loyerHc) + parseFloat(charges);

    const melLocatairePercent = this.honosMelBpLocatairePercentInputTarget.value / 100;
    const melLocataireEuros = parseFloat(this.honosMelBpLocataireEurosInputTarget.value);
    const melLocataireEurosPerMeter = parseFloat(this.honosMelBpLocataireEurosPerMeterInputTarget.value);
    let honorairesLocataire = (loyerCc / 4) * 3;

    if (melLocataireEuros) {
      honorairesLocataire = parseFloat(melLocataireEuros);
    } else if (melLocatairePercent) {
      honorairesLocataire = loyerCc * melLocatairePercent;
    } else if (melLocataireEurosPerMeter) {
      honorairesLocataire = parseFloat(melLocataireEurosPerMeter) * parseFloat(this.propertyAreaInputTarget.value);
    }

    return honorairesLocataire;
  }

  // MAJ selection nb mois loyer si modification du nu/meuble
  actualiseSelectNbMoisDg() {
    const checked = this.isFurnishedInputTargets.filter((target) => target.checked)[0].value === "true";
    let monthDropdown = this.element.querySelector(".oq-form-group.nb_mois_depot_garantie");
    const month1 = monthDropdown.querySelector("[data-form--collection-select--component-value-param='1']");
    let month2 = monthDropdown.querySelector("[data-form--collection-select--component-value-param='2']");

    if (checked) {
      month2.classList.remove("!hidden");
    } else {
      const controller = monthDropdown.querySelector(".oq-select")["form--collection-select--component"];
      if (controller && controller.value == 2) {
        controller.value = { value: 1, text: month1.getAttribute("data-form--collection-select--component-text-param") };
      }
      month2 = monthDropdown.querySelector("[data-form--collection-select--component-value-param='2']");
      month2.classList.add("!hidden");
    }
  }

  // MAJ nb mois loyer pour DG lorsque montant DG manuellement renseigne
  actualiseNbMois() {
    this.moisDgInputController?.clearValue();
    this.moisDgInputTarget.dispatchEvent(new Event("change"));
  }

  get moisDgInputController() {
    this.#moisDgInputController ||= this.moisDgInputTarget["form--collection-select--component"];
    return this.#moisDgInputController;
  }
}
