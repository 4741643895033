import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["leaseRow", "formerTenants", "checkboxFilter"];

  lastYearsDate;
  accordion;
  initialize() {
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  connect() {
    this.checkboxFilterTarget.addEventListener("change", this.toggleFilter);
    this.lastYearsDate = new Date(new Date().getFullYear() - 1, 0, 1);
    this.accordion = this.element.querySelector(".oq-accordion");
  }

  disconnect() {
    this.checkboxFilterTarget.removeEventListener("change", this.toggleFilter);
  }

  dateFormatter(date) {
    try {
      return new Date(date)
    } catch {
      return null
    }
  }

  rotateAccordionIcon() {
    const arrow = this.accordion.querySelector("svg:first-of-type");
    this.accordion.classList.contains("open") ? (arrow.style = "") : (arrow.style.transform = "rotate(180deg)");
  }

  toggleFilter() {
    // On cache les rows hors période
    for (let i = 0; i < this.leaseRowTargets.length; i++) {
      let row = this.leaseRowTargets[i]
      let startDate = this.dateFormatter(row.dataset.leaseStart)
      let endDate = this.dateFormatter(row.dataset.leaseEnd)
      if (
        this.checkboxFilterTarget.checked && !(
          startDate < this.lastYearsDate &&
          (endDate == null || endDate > this.lastYearsDate)
        )
      ) {
        row.classList.add("hidden")
      } else {
        row.classList.remove("hidden")
      }
    }

    // On cache l'accordéon et on affiche la div qui contient les anciens locataires
    if (this.checkboxFilterTarget.checked) {
      this.accordion.classList.add("hidden");
      this.formerTenantsTarget.classList.remove("hidden");
    } else {
      this.accordion.classList.remove("hidden");
      this.formerTenantsTarget.classList.add("hidden");
    }
  }
}
