import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "container", "addButton"];
  static values = {max: Number}

  connect() {
    this.updateButtons()
  }

  addNew() {
    let div = document.createElement("div")
    div.className = 'nested-form-container';
    div.setAttribute("id", Date.now());
    div.innerHTML = this.templateTarget.innerHTML.replace(/CHILD_INDEX/g, (Date.now()).toString());
    this.containerTarget.appendChild(div)
    this.triggerChange()
  }

  delete(e) {
    const form = e.currentTarget.closest(".nested-form-container")
    const destroyInput = form.querySelector("input[name$='[_destroy]']")
    // Handle destroy for persisted objects
    if (destroyInput) {
      destroyInput.value = true
      form.classList.add("!hidden")
    } else {
      form.remove()
    }
    this.triggerChange()
  }

  triggerChange() {
    const event = new Event('change');
    this.element.dispatchEvent(event);
    this.updateButtons()
  }

  updateButtons() {
    if (!this.hasAddButtonTarget)
      return

    const nestedForms = this.element.querySelectorAll(".nested-form-container")
    let recordCount = 0
    nestedForms.forEach(f => {
      // Check if _destroy is checked
      const destroy = ["true", "1"].includes(f.querySelector("input[name$='[_destroy]']")?.value?.toLowerCase())
      if (!destroy)
        recordCount += 1
    })
    if (this.hasMaxValue && this.maxValue > 0 && this.maxValue <= recordCount) {
      this.addButtonTarget.classList.add("hidden")
    } else {
      this.addButtonTarget.classList.remove("hidden")
    }
  }
}
