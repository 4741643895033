import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "lotInput",
    "beginDateInput",
    "encadrementReferenceInput",
    "encadrementReferenceMajoreInput",
    "honosMelInput",
    "honosMelProprioInput",
    "isForcedInput",
    "isForcedInputWrapper",
  ]


  updateFieldsBasedOnBeginDateAndLot() {
    this.updateEncadrementValue()
    this.updateHonorairesMel()
  }

  updateHonorairesMel() {
    let beginDate = this.beginDateInputTarget.value
    let lotId = this.lotInputTarget["form--collection-select--component"].value

    if (!beginDate || !lotId)
      return false;

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      fetch(`/oqoro_admin/lots/${lotId}/honoraires_mel?lease_begin_date=${beginDate}`)
        .then(resp => resp.json())
        .then(data => {
          const {honoraires_frais_mise_en_location, honoraires_frais_mise_en_location_proprio} = data
          this.honosMelInputTarget.value = honoraires_frais_mise_en_location
          this.honosMelProprioInputTarget.value = honoraires_frais_mise_en_location_proprio
        })
    } catch (error) {
    }
  }

  updateEncadrementValue() {
    let beginDate = this.beginDateInputTarget.value
    let lotId = this.lotInputTarget["form--collection-select--component"].value

    if (!beginDate || !lotId)
      return false;

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      fetch(`/admin/lots/${lotId}/property_id`)
        .then(resp => resp.json())
        .then(data => {
          const {property_id} = data
          fetch(`/oqoro_admin/properties/${property_id}/encadrement_value`, {
            method: "POST",
            body: JSON.stringify({lease_date: beginDate}),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            credentials: "same-origin",
          })
            .then(resp => resp.json())
            .then(data => {
              const {reference_value, reference_value_majore} = data
              this.encadrementReferenceInputTarget.value = reference_value
              this.encadrementReferenceMajoreInputTarget.value = reference_value_majore
            })
        })
    } catch (error) {
    }
  }

  toggleIsForced(e) {
    let locEnPlaceValue = e.target.value === "true" ? true : false;
    if (locEnPlaceValue) {
      if (this.isForcedInputWrapperTarget.classList.contains("hidden")) {
        this.isForcedInputWrapperTarget.classList.remove("hidden");
      }
    } else {
      if (!this.isForcedInputWrapperTarget.classList.contains("hidden")) {
        this.isForcedInputWrapperTarget.classList.add("hidden");
      }
      this.isForcedInputTarget.checked = false;
    }
  }
}
