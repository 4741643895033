import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'addendumClauseWrapper',
    'clauseTypeInput',
  ]

  connect() {
    this.initHideStiInputs();
    this.disableUsedTypes();
  }

  disconnect() {
    this.disableUsedTypes();
  }

  getSelectedType() {
    if (typeof(this.clauseTypeInputTarget.dataset.selectedType) !== 'undefined') {
      return(this.clauseTypeInputTarget.dataset.selectedType);
    }
    if (typeof(this.clauseTypeInputTarget['form--collection-select--component']) !== 'undefined') {
      return(this.clauseTypeInputTarget['form--collection-select--component'].value);
    }
    return (null);
  }

  initHideStiInputs() {
    let selectedType = null;
    if (typeof(this.clauseTypeInputTarget.dataset.selectedType) !== 'undefined') {
      selectedType = this.clauseTypeInputTarget.dataset.selectedType;
    }
    this.hideForEachInputs(selectedType);
  }

  hideStiInputs() {
    this.disableUsedTypes();
    let selectedType = this.clauseTypeInputTarget['form--collection-select--component'].value;
    this.hideForEachInputs(selectedType);
  }

  hideForEachInputs(selectedType) {
    let items = this.addendumClauseWrapperTarget.querySelectorAll('.clause-specific-field');
    items.forEach(item => {
      const clauseTypes = item.dataset.clauseTypes.split(' ');
      const isRequired = clauseTypes.includes(selectedType);
      const inputs = item.querySelectorAll('input');
      if (isRequired) {
        if (item.classList.contains('hidden')) {
          item.classList.remove('hidden')
        }
      } else {
        if (!item.classList.contains('hidden')) {
          item.classList.add('hidden')
        }
      }

      inputs.forEach(input => {
        if (isRequired) {
          if (typeof(input.dataset.originalName) !== 'undefined') {
            const inputName = input.dataset.originalName;
            input.removeAttribute('data-original-name');
            input.setAttribute('name', inputName);
          }
        } else {
          input.removeAttribute('required');
          if (typeof(input.dataset.originalName) === 'undefined') {
            if (input.hasAttribute("data-form--collection-select--component-target")) {
              const selectWrapper = input.closest('.oq-select');
              if (typeof(selectWrapper['form--collection-select--component']) !== 'undefined') {
                selectWrapper['form--collection-select--component'].clearValue();
              }
            } else {
              const inputName = input.getAttribute('name');
              input.removeAttribute('name'); // Remove name attribute to exclude from form submission
              input.setAttribute('data-original-name', inputName); // Store original name
            }
          }
        }
      });
    });
  }

  // Disable already selected types from select
  disableUsedTypes() {
    const nestedForms = document.querySelectorAll('.add-addendum-clause-wrapper');
    nestedForms.forEach(nestedForm => {
      this.application.getControllerForElementAndIdentifier(nestedForm, "admin--addendums--clauses-form").enableAllTypes();
    });
    nestedForms.forEach(nestedForm => {
      const addClauseController = this.application.getControllerForElementAndIdentifier(nestedForm, "admin--addendums--clauses-form");
      const thatSelectedType = addClauseController.getSelectedType();
      if (thatSelectedType === null) { return; }

      // Disable this type from other selects
      document.querySelectorAll('.add-addendum-clause-wrapper').forEach(nf => {
        const subAddClauseTarget = this.application.getControllerForElementAndIdentifier(nf, "admin--addendums--clauses-form").clauseTypeInputTarget;
        subAddClauseTarget.querySelectorAll('.menu__item').forEach(menuItem => {
          let itemValue = menuItem.dataset['form-CollectionSelect-ComponentValueParam'];
          if (itemValue == thatSelectedType && !menuItem.classList.contains('disabled')) {
            menuItem.classList.add('disabled');
          }
        });
      })
    });
  }

  enableAllTypes() {
    this.clauseTypeInputTarget.querySelectorAll('.menu__item').forEach(menuItem => {
      menuItem.classList.remove('disabled');
    });
  }
}
