import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

  static targets = ["addressInput"]

  static values = { autocompleteId: String}

  #autocompleteInput

  initialize() {
    this.onInputChanged = this.onInputChanged.bind(this)
  }

  connect() {
    this.element[this.identifier] = this;
    // Check si on est en mode autocomplete
    if (this.hasAutocompleteIdValue) {
      // On recupere l input autocomplete grace a l uniq id genere dans le composant (car a l exterieur du data-controller)
      this.#autocompleteInput = document.querySelector(`[data-id='${this.autocompleteIdValue}']`)
      if (this.#autocompleteInput) {
        // On surveille les changements auto sur l addresse pour verifier qu on ait bien selectionne un element parmi la liste
        this.addressInputTarget.addEventListener("change", this.onInputChanged)
      }
    }
  }

  disconnect() {
    if (this.#autocompleteInput) {
      this.addressInputTarget.removeEventListener("change", this.onInputChanged)
    }
  }

  onInputChanged(e) {
    const formGroup = this.#autocompleteInput.closest(".oq-form-group")
    const fieldError = formGroup.querySelector(".field-error")
      if (!this.addressInputTarget.value) {
        // Si l input adresse est vide (= clear lorsqu on cherche une nouvelle adresse) on affiche une erreur sous l input autocomplete
        formGroup.classList.add("is-invalid")
        fieldError.innerText = "Veuillez sélectionner une adresse parmi la liste."
      } else {
        formGroup.classList.remove("is-invalid")
        fieldError.innerText = ""
      }
  }
}
