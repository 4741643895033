import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "map",
    "autocompleteInput",
    "autocompleteList",
    "addressInput",
    "postcodeInput",
    "cityInput",
    "countryInput",
    "latInput",
    "lngInput",
    "bboxInput",
    "centerInput",
    "citySlugInput",
  ];

  static values = {
    location: String,
    queryTypes: Array,
    mapboxToken: String,
    mapContainerId: String,
    submitOnSelect: Boolean,
    searchApiUrl: String,
    searchInDatabase: Boolean,
    countriesScope: String,
    displayMap: Boolean,
  };

  mapboxgl = null;
  navKeys = ["Enter", "ArrowUp", "ArrowDown"];
  currentActiveElement = -1;
  address = null;
  _dropdownController = null;
  _form = null;
  inputTimer = null;

  initialize() {
    this._initialValue = this.hasAutocompleteInputTarget && this.autocompleteInputTarget.value;
    this.onInputEvent = this.onInputEvent.bind(this);
    this.onKeydownEvent = this.onKeydownEvent.bind(this);
    this.onClickEvent = this.onClickEvent.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  connect() {
    import("!mapbox-gl").then(
      ((mapboxgl) => {
        this.mapboxgl = mapboxgl.default;
        this.init();
      }).bind(this)
    );

    if (this.hasAutocompleteInputTarget) {
      this.autocompleteInputTarget.addEventListener("input", this.onInputEvent);
      this.autocompleteInputTarget.addEventListener("keydown", this.onKeydownEvent);
      this.autocompleteInputTarget.addEventListener("click", this.onClickEvent);
      let dropdown = this.element.querySelector(".dropdown__menu")
      dropdown.style.top = `${this.autocompleteInputTarget.offsetHeight}px`;
    }

    this._form = this.element.closest("form");
    if (this._form) this._form.addEventListener("submit", this.onFormSubmit);
  }

  disconnect() {
    this._form.removeEventListener("submit", this.onFormSubmit);
    this.autocompleteInputTarget.removeEventListener("input", this.onInputEvent);
    this.autocompleteInputTarget.removeEventListener("keydown", this.onKeydownEvent);
    this.autocompleteInputTarget.removeEventListener("click", this.onClickEvent);
  }

  init() {
    if (this.hasMapTarget && this.displayMapValue == true) {
      // Generate uniq container id
      this.mapTarget.id = this.mapContainerIdValue;
      this.mapboxgl.accessToken = this.mapboxTokenValue;
      this._map = new this.mapboxgl.Map({
        container: this.mapContainerIdValue,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [2.2508, 47.1539],
        zoom: 4,
      });

      this._map.on(
        "load",
        (() => {
          this.resizeMap();
          const mapMarker = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          mapMarker.setAttribute("width", "48");
          mapMarker.setAttribute("height", "48");
          mapMarker.setAttribute("viewBox", "0 0 47.409 56");
          mapMarker.innerHTML =
            '<path id="Tracé_821" data-name="Tracé 821" d="M1446.857,333.811h0a23.7,23.7,0,0,0-33.523,0h0a23.7,23.7,0,0,0,0,33.523L1428,382a2.963,2.963,0,0,0,4.19,0l14.667-14.666A23.7,23.7,0,0,0,1446.857,333.811Zm-12.048,21.475h0a6.666,6.666,0,0,1-9.428,0h0a6.666,6.666,0,0,1,0-9.428h0a6.666,6.666,0,0,1,9.428,0h0A6.666,6.666,0,0,1,1434.809,355.286Z" transform="translate(-1406.391 -326.868)" fill="#0076ff"/>';
          this._marker = new this.mapboxgl.Marker({
            element: mapMarker,
          })
            .setLngLat([2.2508, 47.1539])
            .addTo(this._map);
          this._marker.getElement().style.visibility = "hidden";

          if (this.locationValue) {
            this.search(this.locationValue).then(
              ((results) => {
                if (results.length) this.selectResult(results[0]);
              }).bind(this)
            );
          }
        }).bind(this)
      );
    }
    if (this._initialValue) {
      // Initialize hidden fields if autocomplete is populated
      this.search(this._initialValue).then(
        ((results) => {
          if (results?.length && !this.autocompleteInputTarget.value.length) this.selectResult(results[0]);
        }).bind(this)
      );
    }
  }

  onInputEvent(e) {
    let target = e.currentTarget;
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(
      (() => {
        if (target.value.trim() === "") {
          this.getDropdownController().hide();
          return false;
        }
        this.getDropdownController().show();
        this.clearFields();
        this.search(target.value).then(
          ((results) => {
            this.fillAutocompleteList(results);
          }).bind(this)
        );
      }).bind(this),
      400
    );
  }

  onKeydownEvent(e) {
    if (!this.navKeys.includes(e.key)) return true;
    e.preventDefault();
    this.handleKeyboardNav(e);
  }

  onClickEvent(e) {
    e.stopPropagation();
    if (!this.hasMapTarget) {
      e.preventDefault();
      this.autocompleteInputTarget.dispatchEvent(new Event("input"));
    }
  }

  search(query) {
    let element = document.createElement("span");
    element.innerText = "Recherche en cours...";
    !this.autocompleteListTarget.children.length && this.autocompleteListTarget.append(element);

    const apiURL = `${this.searchApiUrlValue}?search=${query}&query_types=${this.queryTypesValue}&search_in_database=${this.searchInDatabaseValue}&countries_scope=${this.countriesScopeValue}`;

    return fetch(apiURL)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.results;
      });
  }

  fillAutocompleteList(results = []) {
    this.currentActiveElement = -1;
    this.autocompleteListTarget.innerHTML = "";
    results.forEach((result) => {
      let item = document.createElement("li");
      let textElem = document.createElement("span");
      textElem.innerText = result.text;
      item.appendChild(textElem);
      item.tabIndex = 1;
      item.dataset.action = "click->dropdown--component#hide";
      item.addEventListener(
        "click",
        ((e) => {
          this.selectResult(result);
          if (this.submitOnSelectValue) this._form.submit();
        }).bind(this)
      );
      this.autocompleteListTarget.append(item);
    });

    if (!results.length) {
      let element = document.createElement("span");
      element.innerText = "Aucun résultat trouvé";
      this.autocompleteListTarget.append(element);
    }
  }

  selectResult(result) {
    if (this.hasAutocompleteInputTarget) this.autocompleteInputTarget.value = result.text;
    // Update hidden fields
    if (this.hasAddressInputTarget) {
      this.addressInputTarget.value = (result.formatted_address || "").trim()
      this.addressInputTarget.dispatchEvent(new Event("change"))
    }
    if (this.hasPostcodeInputTarget) this.postcodeInputTarget.value = result.postcode;
    if (this.hasCityInputTarget) this.cityInputTarget.value = result.city;
    if (this.hasCountryInputTarget) this.countryInputTarget.value = result.country;
    if (this.hasLngInputTarget) this.lngInputTarget.value = result.center[0];
    if (this.hasLatInputTarget) this.latInputTarget.value = result.center[1];
    if (result.bbox.length > 0 && this.hasBboxInputTarget) this.bboxInputTarget.value = [result.bbox[3], result.bbox[0], result.bbox[1], result.bbox[2]];
    if (this.hasCenterInputTarget) this.centerInputTarget.value = result.center;
    if (this.hasCitySlugInputTarget) this.citySlugInputTarget.value = result.slug;
    this.updateMapCoordinates(result.center);
  }

  updateMapCoordinates(coordinates) {
    if (!this._map) return;
    this._map.flyTo({
      center: coordinates,
      zoom: 15,
    });
    if (!this._marker) return;
    this._marker.setLngLat(coordinates);
    this._marker.getElement().style.visibility = "visible";
  }

  resizeMap() {
    this.mapTarget.style.width = "100%";
    this._map.resize();
  }

  getDropdownController() {
    return (
      this._dropdownController || (this._dropdownController = this.element.querySelector("[data-controller='dropdown--component']")["dropdown--component"])
    );
  }

  toggleDropdown(event) {
    if (event.currentTarget.value.trim()) {
      this.getDropdownController().toggle();
    }
  }

  clearFields() {
    if (this.hasAddressInputTarget) {
      this.addressInputTarget.value = "";
      this.addressInputTarget.dispatchEvent(new Event("change"))
    }
    if (this.hasPostcodeInputTarget) this.postcodeInputTarget.value = "";
    if (this.hasCityInputTarget) this.cityInputTarget.value = "";
    if (this.hasCountryInputTarget) this.countryInputTarget.value = "";
    if (this.hasLngInputTarget) this.lngInputTarget.value = "";
    if (this.hasLatInputTarget) this.latInputTarget.value = "";
    if (this.hasBboxInputTarget) this.bboxInputTarget.value = "";
    if (this.hasCenterInputTarget) this.centerInputTarget.value = "";
  }

  onFormSubmit(e) {
    if (!this.submitOnSelectValue) return true;
    if (!this.autocompleteInputTarget.value.trim()) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    if (!this.bboxInputTarget.value) {
      e.preventDefault();
      e.stopPropagation();
      this.search(this.autocompleteInputTarget.value).then(
        ((results) => {
          if (results.length > 0) this.selectResult(results[0]);
          this._form.submit();
        }).bind(this)
      );
      return true;
    }
    return true;
  }

  // Navigation au clavier dans le dropdown
  handleKeyboardNav(event = {}) {
    const key = event.key;

    let resultsList = this.autocompleteListTarget.children;
    this.autocompleteListTarget.querySelectorAll("li.active").forEach((element) => {
      element.classList.remove("active");
    });

    if (key === "ArrowDown") {
      let currentActiveElementIsNotLastItem = this.currentActiveElement < resultsList.length - 1;
      if (currentActiveElementIsNotLastItem) {
        this.currentActiveElement++;
      }
    } else if (key === "ArrowUp") {
      let currentActiveElementIsNotFirstItem = this.currentActiveElement > 0;
      if (currentActiveElementIsNotFirstItem) {
        this.currentActiveElement--;
      }
    }

    let currentListItem = null;
    if (this.currentActiveElement >= 0) {
      currentListItem = resultsList[this.currentActiveElement];
      if (currentListItem.tagName === "SPAN") return;
      currentListItem.classList.add("active");
    }

    if (key === "Enter" && currentListItem) {
      currentListItem.click();
      this.currentActiveElement = -1;
    }
  }
}
