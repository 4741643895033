import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.disableFirstCopyDate();
  }

  setFromPreviousDate(e) {
    const elem = e.target;
    const elemDateInput = elem.closest('.valid_from_wrapper').querySelector('input[type="date"]');
    const thatId = elem.closest('.nested-form-container').getAttribute("id");
    let previousDateValue = null;
    this.element.querySelectorAll('.valid_from_wrapper').forEach((e) => {
      const elemId = e.closest('.nested-form-container').getAttribute("id");
      const elemDateValue = e.querySelector('input[type="date"]').value;
      if (elemId == thatId) {
        if (previousDateValue.length > 0) {
          elemDateInput.value = previousDateValue;
        }
        return;
      }
      previousDateValue = elemDateValue;
    })
  }

  disableFirstCopyDate() {
    this.element.querySelectorAll('.valid_from_wrapper').forEach((elem, index) => {
      if (index == 0) {
        elem.querySelector('.set-from-previous-trigger').classList.add('hidden');
      }
    });
  }

  formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }
}
