import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resourceInput", "resourceTypeInput", "resourceInputGroup"]
  static values = {resourceUrls: Object}

  #resourceTypeController
  #resourceController

  connect() {
    this.updateInputs()
  }
  updateInputs() {
    if (this.resourceTypeValue) {
      this.resourceInputGroupTarget.classList.remove("hidden")
      this.updateResourceUrl()
    } else {
      this.resourceInputGroupTarget.classList.add("hidden")
      this.resourceController?.clearValue()
    }
  }
  updateResourceUrl() {
    if (!this.resourceController)
      return
    // On met a jour l url ajax si changement de type de resource liee
    const url = this.resourceUrlsValue[this.resourceTypeValue]
    if (url != this.resourceController.ajaxUrl) {
      this.resourceController.clearValue()
      this.resourceController.setAjaxUrl(url)
    }
  }
  get resourceTypeValue() {
    this.#resourceTypeController ||= this.resourceTypeInputTarget["form--collection-select--component"]
    return this.#resourceTypeController?.value
  }

  get resourceController() {
    this.#resourceController ||= this.resourceInputTarget["form--collection-select--component"]
    return this.#resourceController
  }
}
