import {Controller} from "@hotwired/stimulus"
import fuzzysort from 'fuzzysort'
import {template, isEmpty, isEqual} from "lodash";

export default class extends Controller {
  static targets = ["input", "prompt", "dropdown", "searchInput", "optionTemplate", "displayOptionTemplate", "inputTemplate", "inputList"]

  static values = {
    multiple: Boolean,
    prompt: String,
    ajaxUrl: String,
    collection: Array,
    initialValue: String,
    includeBlank: Boolean,
    submitOnChange: Boolean
  }

  #form;
  #value = [];
  #initialValue = [];
  #optionTemplate;
  #displayOptionTemplate;
  #inputTemplate;
  #inputTimer = null;

  initialize() {
    this.onBlur = this.onBlur.bind(this)
    this.element[this.identifier] = this;
    this.#form = this.element.closest("form")
    this.appendSelectOption = this.appendSelectOption.bind(this);
    this.search = this.search.bind(this);
    this.#optionTemplate = template(this.optionTemplateTarget.innerHTML);
    this.#displayOptionTemplate = template(this.displayOptionTemplateTarget.innerHTML);
    this.#inputTemplate = template(this.inputTemplateTarget.innerHTML);
    this.initializeValue();
    this.element.dispatchEvent(new Event('initialized'));
  }

  connect() {
    this.dropdownTarget.addEventListener("close", this.onBlur)
  }

  disconnect() {
    this.dropdownTarget.removeEventListener("close", this.onBlur)
  }

  initializeValue() {
    if (this.initialValueValue) {
      const initialValue = JSON.parse(this.initialValueValue);
      initialValue.forEach(v => {
        this.#value.push(v);
        this.element.querySelector(`[data-form--collection-select--component-value-param="${v.value}"]`)?.classList?.add("selected");
      })
      this.#initialValue = [...this.#value]
      this.updateInputs();
    }
    this.updatePrompt();
  }

  onBlur() {
    if (!this.submitOnChangeValue)
      return false
    if (isEqual(this.#value, this.#initialValue))
      return false
    this.submitForm()
  }

  submitForm() {
    if (!this.#form)
      return false
    this.#form.requestSubmit()
  }

  clearValue() {
    this.#value = []
    this.updateInputs()
    this.updatePrompt();
  }

  selectOption(e) {
    const menuItem = e.currentTarget.closest('.menu__item');
    if (menuItem && menuItem.classList.contains('disabled')) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const {params: {value, text}} = e;
    const v = {value, text};
    let selected = false;
    if (this.#value.find(val => val.value == v.value)) {
      // if (this.multipleValue)
      this.removeValue(v);
    } else {
      selected = true;
      if (this.multipleValue) {
        this.addValue(v);
      } else {
        this.updateValue(v)
      }
    }
    if (this.multipleValue) {
      const option = this.createElementFromHTML(this.#optionTemplate({
        icon: selected ? "check" : "checkbox-empty",
        text: text,
        value: value,
      }));
      if (selected)
        option.classList.add("selected");
      const currentOption = this.element.querySelector(`.option[data-form--collection-select--component-value-param="${value}"]`)
      if (currentOption) {
        currentOption.replaceWith(option);
      }
    }
    this.updatePrompt();
  }

  addOptions(options = []) {
    options.forEach(opt => {
      this.addValue(opt);
    })
    this.updatePrompt();
  }

  setPrompt(text) {
    if (!this.hasPromptTarget)
      return false
    this.promptTarget.innerHTML = text;
  }

  addValue(value) {
    const hasChanged = !(value in this.#value)
    const oldValue = this.#value
    this.#value = this.#value || []
    this.#value.push(value);
    this.updateInputs();
    this.element.value = this.#value
    if (hasChanged)
      this.triggerChange()
  }

  removeValue(value) {
    const hasChanged = value != this.#value
    const index = this.#value.findIndex(val => val.value == value.value);
    if (index !== -1) {
      this.#value.splice(index, 1);
    }
    this.updateInputs();
    this.element.value = this.#value
    if (hasChanged)
      this.triggerChange()
  }

  updateValue(value) {
    const oldValue = this.#value
    this.#value = [value];
    this.updateInputs();
    this.element.value = this.#value
    if (oldValue != this.#value)
      this.triggerChange()
  }

  triggerChange() {
    const event = new Event('change');
    this.element.dispatchEvent(event);
  }

  updateInputs() {
    this.inputListTarget.innerHTML = "";
    if (this.#value.length > 0) {
      this.#value.forEach(v => {
        const input = this.createElementFromHTML(this.#inputTemplate({
          value: v.value,
        }));
        this.inputListTarget.appendChild(input);
      })
    } else {
      if (this.includeBlankValue) {
        const input = this.createElementFromHTML(this.#inputTemplate({value: ""}));
        this.inputListTarget.appendChild(input);
      }
    }
  }

  updatePrompt() {
    if (!this.hasPromptTarget)
      return false
    this.promptTarget.innerHTML = "";
    if (!isEmpty(this.#value)) {
      this.promptTarget.classList.remove("no-value");
      this.#value.forEach(v => {
        const optionDisplay = this.createElementFromHTML(this.#displayOptionTemplate({
          text: v.text,
          value: v.value,
        }));
        this.promptTarget.appendChild(optionDisplay);
      })
    } else {
      this.setPrompt(this.promptValue || "Sélectionner...")
      this.promptTarget.classList.add("no-value");
    }
  }

  onSearch() {
    setTimeout(this.search, 50);
  }

  search() {
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(
      (async () => {
        const searchString = this.searchInputTarget.value;
        let results = [];
        if (this.ajaxUrlValue) {
          let url_for_term = searchString ? (this.ajaxUrlValue.includes('?') ? `&term=${searchString}` : `?term=${searchString}`) : "";
          results = await fetch(this.ajaxUrlValue + url_for_term)
            .then((response) => response.json())
            .then((response) => {
              return response.results.map(r => ({obj: {text: r.text, value: r.id}}));
            })
        } else {
          results = fuzzysort.go(searchString, this.collectionValue, {key: 'text'})
        }
        this.clearList();
        if (results.length > 0) {
          results.forEach(this.appendSelectOption);
        } else {
          const div = document.createElement("div")
          div.setAttribute("class", "p-2 text-light text-sm italic")
          div.innerText = "Aucun résultat"
          this.dropdown.listTarget.appendChild(div);
        }
      }).bind(this),
      400
    );
  }

  appendSelectOption({obj}) {
    const option = this.createElementFromHTML(this.#optionTemplate({
      text: obj.text,
      value: obj.value,
    }));
    if (this.#value.find(v => v.value == obj.value)) {
      option.classList.add("selected");
    }
    this.dropdown.listTarget.appendChild(option);
    // return option;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes.
    return div.firstChild;
  }

  clearList() {
    this.dropdown.listTarget.innerHTML = "";
  }

  ajaxUrlValueChanged(value) {
    if (value)
      this.search()
  }

  setAjaxUrl(ajaxUrl) {
    this.element.setAttribute("data-form--collection-select--component-ajax-url-value", ajaxUrl)
  }

  get ajaxUrl() {
    return this.ajaxUrlValue
  }

  get dropdown() {
    return this.dropdownTarget["dropdown--component"];
  }

  get value() {
    if (this.multipleValue == true)
      return this.#value;
    return this.#value[0]?.value || null;
  }

  set value(val) {
    this.updateValue(val)
    this.updatePrompt();
  }
}
