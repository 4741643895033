import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "frame"
  ]
  client = null;

  connect() {
    this.client = ZAFClient.init();
    if (this.client) {
      this.client.invoke('resize', {width: '100%', height: '80vh'});
      this.getContext()
    }

    // Update browser history on turbo frame load so redirections from resource controller work fine and redirect to context frame
    document.addEventListener("turbo:frame-load", (event) => {
      const frame = event.target;
      const newUrl = frame.src;
      if (newUrl) {
        history.replaceState({}, '', newUrl);
      }
    });
  }

  getContext() {
    this.client.get('ticket').then((async function (data) {
      // Récupérer l'ID du probleme lié à l'incident s'il existe
      let problemId
      let tickeTypeObj = await this.client.get('ticket.type')
      if (tickeTypeObj['ticket.type'] === 'incident') {
        let problemObj = await this.client.get('ticket.customField:problem_id')
        problemId = problemObj['ticket.customField:problem_id'];
      }

      // Id du ticket courant
      let ticketId = data.ticket.id;

      let userEmails = data.ticket.requester.identities.filter(identity => identity.type == "email").map(o => o["value"])

      // IMPORTANT : En contexte, on veut le problème lié à l'incident s'il existe, sinon on prend le ticket courant
      let contextTicketId = problemId || ticketId
      this.frameTarget.src = `/oqoro_admin/zendesk_app/sidebar/context?emails=${encodeURIComponent(userEmails)}&ticket_id=${encodeURIComponent(contextTicketId)}&problem_id=${encodeURIComponent(problemId)}`;
    }).bind(this))
  }
}
