import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'honoProprioInput',
    'honoProprioHiddenInput',
    'noExpenseProprioBtn',
    'addExpenseProprioBtn',
    'addAvoirProprioBtn',
    'avoirProprioInputWrapper',
    'avoirProprioInput',
    'honoLocInput',
    'honoLocHiddenInput',
    'noExpenseLocBtn',
    'addExpenseLocBtn',
    'addAvoirLocBtn',
    'avoirLocInputWrapper',
    'avoirLocInput',
  ]

  removeExpenseProprio() {
    this.honoProprioInputTarget.value = 0.0;
    this.honoProprioHiddenInputTarget.value = 0.0;
    this.noExpenseProprioBtnTarget.classList.add('hidden');
    this.addExpenseProprioBtnTarget.classList.remove('hidden');
    if (!this.avoirProprioInputWrapperTarget.classList.contains('hidden')) {
      this.avoirProprioInputWrapperTarget.classList.add('hidden');
    }
    if (!this.addAvoirProprioBtnTarget.classList.contains('hidden')) {
      this.addAvoirProprioBtnTarget.classList.add('hidden');
    }
    this.avoirProprioInputTarget.value = null;
  }

  addExpenseProprio() {
    const originalValue = parseFloat(this.honoProprioInputTarget.dataset.originalvalue);
    this.honoProprioInputTarget.value = originalValue;
    this.honoProprioHiddenInputTarget.value = originalValue;
    this.noExpenseProprioBtnTarget.classList.remove('hidden');
    this.addExpenseProprioBtnTarget.classList.add('hidden');
    this.addAvoirProprioBtnTarget.classList.remove('hidden');
  }


  addAvoirProprio() {
    this.avoirProprioInputWrapperTarget.classList.remove('hidden');
    this.addAvoirProprioBtnTarget.classList.add('hidden');
  }

  closeAddAvoirProprio() {
    this.avoirProprioInputWrapperTarget.classList.add('hidden');
    this.addAvoirProprioBtnTarget.classList.remove('hidden');
    this.avoirProprioInputTarget.value = null;
  }

  removeExpenseLoc() {
    this.honoLocInputTarget.value = 0.0;
    this.honoLocHiddenInputTarget.value = 0.0;
    this.noExpenseLocBtnTarget.classList.add('hidden');
    this.addExpenseLocBtnTarget.classList.remove('hidden');
    if (!this.avoirLocInputWrapperTarget.classList.contains("hidden")) {
      this.avoirLocInputWrapperTarget.classList.add("hidden");
    }
    if (!this.addAvoirLocBtnTarget.classList.contains('hidden')) {
      this.addAvoirLocBtnTarget.classList.add('hidden');
    }
    this.avoirLocInputTarget.value = null;
  }

  addExpenseLoc() {
    const originalValue = parseFloat(this.honoLocInputTarget.dataset.originalvalue);
    this.honoLocInputTarget.value = originalValue;
    this.honoLocHiddenInputTarget.value = originalValue;
    this.noExpenseLocBtnTarget.classList.remove('hidden');
    this.addExpenseLocBtnTarget.classList.add('hidden');
    this.addAvoirLocBtnTarget.classList.remove('hidden');
  }

  addAvoirLoc() {
    this.avoirLocInputWrapperTarget.classList.remove("hidden");
    this.addAvoirLocBtnTarget.classList.add('hidden');
  }

  closeAddAvoirLoc() {
    this.avoirLocInputWrapperTarget.classList.add("hidden");
    this.addAvoirLocBtnTarget.classList.remove('hidden');
    this.avoirLocInputTarget.value = null;
  }
}
